.navItem {
    font-size: 16px;
    color: #6D7175;
    font-style: normal;
    margin-left: 60px;
}

.nav-container {
    position: relative;
}

.active {
    color: white;
}

.nav-button {
    display: none;
    margin-left: 24px;
    margin-top: 67px;
    cursor: pointer;
}

.navItem:hover {
    color: white;
}

.active {
    color: white;
}

.navigation {
    padding-top: 60px;
    padding-left: 84px;
    padding-right: 84px;
}

.navigation-mobile {
    z-index: 100;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(268.99deg, #0B1218, rgba(43, 52, 61, 1));
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 63px;
    padding-left: 24px;
}

.nav-holder {
    background: rgba(43, 52, 61, 1);
}

.logo-mobile {
    margin-top: -12px;
    visibility: hidden;
    justify-content: center;
}

.item-holder-mobile {
    max-width: 250px;
    display: flex;
    flex-direction: column;
}

.item-holder-mobile .navItem {
    margin-bottom: 20px;
}

.cancel-button-holder {
    position: relative;
}

.cancel-button {
    margin-top: -56px;
    position: absolute;
    right: 24px;
    cursor: pointer;
}

.hamburger-open {
    opacity: 0.5;
}

.flex-logo {
    display: none;
}

.big-logo {
    margin-top: -24px;
    width: 130px;
    height: auto;
}

.hamburger {
    width: 20px;
    height: 20px;
}

.hamburger-open {
    margin-top: 4px;
    width: 20px;
    height: 20px;
    margin-bottom: 36px;
}

.cancel {
    width: 20px;
    height: 20px;
}

.small-logo {
    width: 130px;
    height: auto;
}


/*-----------------------------MOBILE portrait----------------------------------*/

@media only screen and (max-width: 600px) {
    .big-logo {
        margin-top: 0;
    }

    .flex-logo {
        display: block;
    }

    .nav-button {
        display: block;
    }

    .navigation {
        display: none;
    }

    .navItem {
        font-size: 14px;
        margin-left: 0px;
    }

    .logo-mobile {
        visibility: visible;
    }

}


/*-----------------------------MOBILE LANDSCAPE----------------------------------*/

@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 916px) 
                   and (orientation: landscape) {

    .big-logo {
        margin-top: 0;
    }

    

    .flex-logo {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .nav-button {
        display: block;
        margin-top: 0;
        padding-top: 30px;
    }

    .navigation {
        display: none;
    }

    .navItem {
        font-size: 14px;
        margin-left: 0px;
    }

    .logo-mobile {
        visibility: visible;
    }
    .cancel-button {
        right: 60px;
        
    }

    .small-logo{
        width: 80px
    }

}


/*-----------------------------TABLET portrait----------------------------------*/

@media only screen and (min-device-width: 760px) 
                   and (max-device-width: 1024px) 
                   and (orientation: portrait) {

    .big-logo {
        margin-top: 0;
    }

    .flex-logo {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .nav-button {
        display: block;
        margin-top: 0;
        padding-top: 40px;
    }

    .navigation {
        display: none;
    }

    .navItem {
        font-size: 14px;
        margin-left: 0px;
    }

    .logo-mobile {
        visibility: visible;
    }
    .cancel-button {
        right: 60px;
        
    }
    .hamburger {
        width: 30px;
        height: 30px;
    }

}