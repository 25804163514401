
.about-container {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;
}

.about-holder {
    padding-left: 0;
}

.text-dark {
    color: rgba(255, 255, 255, 0.2);
    margin-left: 5px;
}

.about-subtitle {
    width: 100%;
    align-items: center;
    max-width: 535px;
    font-size: 20px;
    margin-bottom: 56px;
}

.home-divider {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
    margin-bottom: 48px;
    height: 1px;
    width: 71px;
    background: rgba(255, 255, 255, 0.1);
}

.about-thanks {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.4px;
    margin-bottom: 45px;
    color: rgba(255, 255, 255, 0.2);
    margin-top: 0;
    display: block;
}

.about-thanks-mobile {
    display: none;
}

.thanks-holder {
    display: block;
}

.thanks-item {
    display: flex;
    margin-bottom: 15px;
}

.thanks-item-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.thanks-item .image-holder-thanks-item {
    display: flex;
    justify-items: center;
    align-content: center;
    margin-right: 24px;
    width: 81px;
    height: 81px;
    background: #373f46;
    border-radius: 100%;
}

.image-holder-thanks-item img {
    width: 81px;
    height: 81px;
}

.special-image {
    margin-left: 6px;
    align-self: center;
    width: 70px !important;
    height: 70px !important;
}

.dream-icon {
    width: 70px !important;
    height: 70px !important;
    padding-left: 10px;
    padding-top: 10px;
}

.sedmi-icon {
    width: 67px !important;
    height: 67px !important;
    padding-left: 13px;
    padding-top: 13px;
}

.about-mobile-title {
    font-size: 28px;
}

.home-divider-mobile {
    visibility: hidden;
    display: flex;
    align-items: flex-start;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 1px;
    width: 43px;
    background: rgba(255, 255, 255, 0.4);
}

.thanks-holder-mobile {
    display: none;
}

@media only screen and (max-width: 600px) {
    .about-container {
        margin-top: 36px;
        padding-right: 25px;
        padding-left: 25px;
    }

    .aboutUs-holder {
        display: block;
    }

    .about-subtitle {
        font-size: 17px;
        margin-bottom: 72px;
    }

    .home-divider {
        display: none;
    }

    .about-thanks {
        display: none;
        margin-top: 72px;
        margin-bottom: 32px;
    }

    .about-thanks-mobile {
        display: block;
        margin-bottom: 32px;
        font-size: 17px;
    }

    .home-divider-mobile {
        visibility: visible;
        margin-top: 16px;
        margin-bottom: 40px;
    }

    .about-mobile-title {
        display: block;
        font-size: 25px;
    }
    .thanks-holder {
        display: none;
    }

    .thanks-item-mobile {
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 68px;
        padding: 5px 20px;
        margin-bottom: 15px;
        background: rgba(255, 255, 255, 0.07);
        border-radius: 22px;
    }

    .thanks-item-mobile img{
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 100%;
    }
    .thanks-holder-mobile {
        display: block;

    }

    .text-dark {
        color: rgba(255, 255, 255, 0.2);
        margin-left: 0px;
    }
}
