@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'AvenirBody';
    src: local('Avenir'), url(assets/avenir/Avenir-Medium.woff) format('woff');
}

@font-face {
    font-family: "AvenirHeavy";
    src: local('Avenir'), url(assets/avenir/Avenir-Heavy.woff) format('woff');
}

@font-face {
    font-family: "AvenirBlack";
    src: local('Avenir'), url(assets/avenir/Avenir-Black.woff) format('woff');
}

@font-face {
    font-family: "AvenirRegular";
    src: local('Avenir'), url(assets/avenir/Avenir-Regular.woff) format('woff');
}


.avenir-body {
    font-family: AvenirBody, sans-serif !important;
}

.avenir-title {
    font-family: AvenirBlack, sans-serif !important;
    letter-spacing: 0.04em;
}

.avenir-subtitle {
    font-family: AvenirHeavy, sans-serif !important;
}

.avenir-regular {
    font-family: AvenirRegular, sans-serif !important;
}

body {
    background: linear-gradient(268.99deg, #0B1218 21.56%, rgba(43, 52, 61, 0.97) 91.79%);
    font-family: AvenirBody, sans-serif;
    color: white;
}

/*home*/

.w-55 {
    width: 55px;
    height: 55px;
}

.w-35 {
    width: 35px;
    height: 35px;
}

.web-image {
    max-width: 750px;
    margin-top: -34px;
}

.sc-logo {
    position: absolute;
    right: 20px;
    bottom: 170px;
}

.sc-logo-mobile {
    position: absolute;
    width: 80px;
    height: 80px;
    right: 10%;
    bottom: 15%;
}

.home-container {
    position: relative;
    padding-left: 84px;
    padding-right: 84px;
}

.title-holder {
    line-height: 125%;
    font-size: 75px;
    padding-top: 1px;
}

.home-left {
    margin-top: 112px;
}

.divider {
    margin-top: 24px;
    margin-bottom: 48px;
    width: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.title-divider {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.home-subtitle {
    font-size: 28px;
}

.home-gradient-container {
    z-index: -1;
    width: 90vw;
    background: linear-gradient(272.7deg, #AAF0D1 2.25%, rgba(170, 240, 209, 0) 95.37%);
    position: absolute;
    top: 184px;
    right: 0;
    bottom: 0;
    height: calc(100% - 184px);
    padding-bottom: 0;
}

.title-image-holder {
    visibility: visible;
    display: flex;
    justify-content: space-between;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    margin-top: 60px;
}

.copyright {
    width: 100%;
    justify-content: flex-end;
    align-self: flex-end;
    text-align: center;
    font-size: 12px;
}

.d-flex {
    display: flex;
}

.mobile-image {
    margin-right: auto;
    margin-left: auto;
    width: 80%;
}

.margin-right-24 {
    margin-right: 24px;
}

.margin-right-20 {
    margin-right: 20px;
}

.title-image-holder-mobile {
    display: none;
}

.stores {
    margin-top: 60px;
    display: flex;
}

.footer-mobile {
    display: none;
}

.weapay-title {
    color: white;
    opacity: 0.2;
    font-size: 80px;
}

/*About Us*/
.aboutUs-holder {
    padding-bottom: 60px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.aboutUs-title {
    margin-top: 25px;
    font-size: 28px;
}

.text-dark-aboutUs {
    color: rgba(255, 255, 255, 0.2);
}

.aboutUs-data {
    font-size: 16px;
    max-width: 450px;
}

.aboutUs-data div {
    margin-top: 24px;
}


/*Contact*/

.contact-subtitle {
    font-size: 17px;
}

.green-text {
    color: #AAF0D1;
}

.contact-data {
    max-width: 450px;
}

.form-holder {
    margin-top: 72px;
    max-width: 500px;
}

.input-holder .label {
    font-size: 12px;
    margin-bottom: 4px;
    color: #6D7175;
}

.input-field{
    position: relative;
}

input:focus ~ .floating-label,
input:not(:focus):valid ~ .floating-label{
  top: -20px;
  font-size: 14px;
  opacity: 1;
}
textarea:focus ~ .floating-label_textarea,
textarea:not(:focus):valid ~ .floating-label_textarea{
  top: -25px;
  font-size: 14px;
  opacity: 1;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 2px;
  bottom: 5px;
  color: #6D7175;
  transition: 0.2s ease all;
}
.floating-label_textarea {
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 10px;
  color: #6D7175;
  transition: 0.2s ease all;
}

.textarea-holder{
    position: relative;
}

.textarea-holder .label {
    font-size: 12px;
    margin-bottom: 4px;
    color: #6D7175;
}

.input-holder {
    margin-bottom: 24px;
}

.input {
    border: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    font-size: 16px;
    font-family: Avenir, sans-serif;
    border-bottom: 1.5px solid #6D7175;
}

.input:focus-visible {
    outline: none;
    border: none !important;
    border-bottom: 1.5px solid #AAF0D1 !important;
}

.textarea-holder {
    margin-top: 74px;
    width: 100%;
}

.textarea {
    width: 100%;
    border: 1.5px solid #6D7175;
    border-radius: 20px;
    background: transparent;
    padding: 12px;
    resize: none;
}

.button-holder {
    margin-top: 33px;
    transition: 0.2s ease all;

}

.button-holder button {
    display: flex;
    margin-left: auto;
}

.button-holder button:hover {
    opacity: 0.5;
}

.mock-div {
    width: 100%;
}

.social {
    width: 100%;
    justify-content: flex-end;
}

.social-web {
    width: 190px;
    height: auto;
}

.border-white {
    border-color: white;
}

.label-test{
    display: block;
    font-size: 12px;
    transform: translate(0, -2rem);
}

.input-test {
    border: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    padding-bottom: 2px;
    width: 100%;
    font-size: 16px;
    font-family: Avenir, sans-serif;
    border-bottom: 1.5px solid #6D7175;
}

.input-test:focus-visible {
    outline: none;
    border: none !important;
    border-bottom: 1.5px solid #AAF0D1 !important;

}
.textarea:focus-visible {
    outline: none;
    border: 1.5px solid #AAF0D1 !important;

}
@supports (not (-ms-ime-align:auto)) {
    .label-test {
        color: #999;
        font-size: 15px;
        transform: translate(0.25rem, -1.5rem);
        transition: all 0.2s ease-out;
    }

    .input-test:focus + .label-test,
    .input-test:not(:placeholder-shown) + .label-test {
        color: #999;
        font-size: 12px;
        transform: translate(0, -2.75rem);
    }
}

/*-----------------------------MOBILE PORTRAIT----------------------------------*/


@media only screen and (max-width: 480px) {
    .social-web {
        width: 155px;
        height: auto;
    }

    .form-holder {
        margin-top: 40px;
    }

    .divider {
        margin-top: 16px;
        margin-bottom: 40px;
    }

    .footer-mobile {
        margin-top: 64px;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }

    .sc-logo {
        display: none;
    }

    .aboutUs-data {
        font-size: 14px;
    }

    .aboutUs-title {
        margin-top: 36px;
        font-size: 25px;
    }

    .weapay-title {
        font-size: 24px;
    }

    .home-container {
        position: relative;
        padding-left: 25px;
        padding-right: 25px;
    }

    .title-holder {
        font-size: 20px;
        margin-top: -32px;
    }

    .home-subtitle {
        max-width: 250px;
        font-size: 15px;
    }

    .title-image-holder {
        display: none;
        margin-bottom: 55px;
    }

    .footer {
        display: none;
    }

    .margin-right-24 {
        margin-right: 0;
        margin-bottom: 24px;
    }

    .title-image-holder-mobile {
        display: block;
    }

    .home-gradient-container {
        top: 100px;
        height: calc(100% - 100px);
    }

    .stores {
        margin-top: 0;
        display: block;
        margin-bottom: 37px;
    }

    .copyright {
        margin-top: 25px;
        width: auto;
        align-self: center;
        margin-bottom: 24px;
        font-size: 10px;
    }

    .home-left {
        margin-top: 6px;
    }

}

/*-----------------------------MOBILE LANDSCAPE----------------------------------*/

@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 916px) 
                   and (orientation: landscape) {
                    .social-web {
                        width: 155px;
                        height: auto;
                    }
                
                    .form-holder {
                        margin-top: 40px;
                    }
                
                    .divider {
                        margin-top: 16px;
                        margin-bottom: 40px;
                    }
                
                    
                
                    .sc-logo {
                        display: none;
                    }
                
                    .aboutUs-data {
                        font-size: 14px;
                    }
                
                    .aboutUs-title {
                        margin-top: 36px;
                        font-size: 25px;
                    }
                
                    .weapay-title {
                        font-size: 24px;
                    }
                
                    .home-container {
                        position: relative;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                
                    .title-holder {
                        font-size: 20px;
                        margin-top: -32px;
                    }
                
                    .home-subtitle {
                        max-width: 250px;
                        font-size: 15px;
                    }
                
                    .title-image-holder {
                        display: block;
                        margin-bottom: 55px;
                    }
                
                    .footer {
                        display: block;
                    }
                
                    .margin-right-24 {
                        margin-right: 0;
                        margin-bottom: 24px;
                    }
                
                    .title-image-holder-mobile {
                        display: none;
                    }
                    .title-image-holder {
                        display: flex;
                    }
                
                    .home-gradient-container {
                        height:80%;
                        top: 120px;
                    }
                
                    .stores {
                        margin-top: 0;
                        display: block;
                        margin-bottom: 37px;
                    }
                
                    .copyright {
                        margin-top: 25px;
                        width: auto;
                        align-self: center;
                        margin-bottom: 24px;
                        font-size: 10px;
                    }
                
                    
                    .web-image{
                        width: 70%;
                        margin-top: 20px;
                        margin-right: 20px;
                    }
                    .home-right{
                        display: flex;
                        align-items: center;
                        justify-content:flex-end;
                    }
                    .home-left{
                        width: 60%;
                        margin-left: 40px;
                        margin-top: 60px;
                    }
                    .title-holder{
                        font-size: 2.1rem
                    }
                    .footer{
                        padding:0
                    }
                    .social{
                        justify-content:center;
                    }
                    .w-55 {
                        width: 25px;
                        height: 25px;
                    }

}

/*-----------------------------TABLET portrait----------------------------------*/

@media only screen and (min-device-width: 760px) 
                   and (max-device-width: 1024px) 
                   and (orientation: portrait) {
                    .social-web {
                        width: 155px;
                        height: auto;
                    }
                
                    .form-holder {
                        margin-top: 40px;
                    }
                
                    .divider {
                        margin-top: 16px;
                        margin-bottom: 40px;
                    }
                
                    .footer-mobile {
                        margin-top: 64px;
                        display: flex;
                        flex-direction: column;
                        justify-items: center;
                        align-items: center;
                    }
                
                    .sc-logo {
                        display: none;
                    }
                
                    .aboutUs-data {
                        font-size: 14px;
                    }
                
                    .aboutUs-title {
                        margin-top: 36px;
                        font-size: 25px;
                    }
                
                    .weapay-title {
                        font-size: 24px;
                    }
                
                    .home-container {
                        position: relative;
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                
                    .title-holder {
                        font-size: 20px;
                        margin-top: -32px;
                    }
                
                    .home-subtitle {
                        max-width: 250px;
                        font-size: 15px;
                    }
                
                    .title-image-holder {
                        display: none;
                        margin-bottom: 55px;
                    }
                
                    .footer {
                        display: none;
                    }
                
                    .margin-right-24 {
                        margin-right: 0;
                        margin-bottom: 24px;
                    }
                
                    .title-image-holder-mobile {
                        display: flex;
                    }
                
                    .home-gradient-container {
                        height: 100%;
                        top: 20px;
                    }
                
                    .stores {
                        margin-top: 0;
                        display: block;
                        margin-bottom: 37px;
                    }
                
                    .copyright {
                        margin-top: 25px;
                        width: auto;
                        align-self: center;
                        margin-bottom: 24px;
                        font-size: 10px;
                    }
                
                    .home-left {
                        margin-top: 6px;
                    }
                    .mobile-image{
                        width: 80%;
                    }

}